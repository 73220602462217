<template>
<div>
    <!-- <v-card> -->
    <v-container fluid> 
        <v-tabs v-model="currentItem" :active-class="$vuetify.theme.dark ? 'primary--text' : 'black--text'" :slider-color="$vuetify.theme.dark ? 'primary--text' : 'black--text'"  :key="$vuetify.theme.dark+$route.path">
            <v-menu bottom left v-for="(item,i) in tabs" :key="i">

                <template v-slot:activator="{ on, attrs }">
                    <v-tab  v-show="checkAuth(item)" :to="item.to" @click="item.to == $route.path ? '' : route(item.to)" v-bind="attrs" v-on="on" :class="$route.path == item.to || childrens($route.path, item) ? 'tab2' : '' ">
                        
                        <v-icon  class="ml-4">{{ item.icon }}</v-icon>
                        <span style="font-weight: 600; font-size: 16px" :color="$vuetify.theme.dark ? 'white--text' : 'red--text'">{{ item.title }}</span>
                        <v-icon class="mr-2">{{item.childrens ? 'keyboard_arrow_down' : ''}}</v-icon>

                    </v-tab>
                </template>

                <v-list v-if="item.childrens " outlined="" class="mt-12">
                    <v-list-item v-for="(children, i) in item.childrens" :key="i" :to="item.to == $route.path ? '' : children.to">
                        <v-icon class="ml-2">{{ children.icon }}</v-icon>
                        <h4>{{ children.title }}</h4>
                    </v-list-item>
                </v-list>

            </v-menu>

        </v-tabs>
    </v-container>
    <!-- </v-card> -->
</div>
</template>

<script>
import tabs from './navigation'
export default {
    data: () => ({
        tabs: [
            ...tabs
        ],
        currentItem: 'home',

    }),

    methods: {
        route(to) {
            if (to) this.$router.push(to);
        },

        childrens(route, item) {
            if (item.childrens) {
                return item.childrens.some(x => x.to == route);
            }
        },

        checkAuth(item) {
            if (this.$store.state.user.userType.id == this.$global.state.roleType.admin) {
                return true
            } else {
                if(item.childrens){
                    for(var i = 0; i < item.childrens.length ; i++){
                        if (this.$store.state.user.roles.find(x => x.key == item.childrens[i].to.slice(1))) {
                            return true;
                        } 
                    }
                }else if(!item.childrens){
                    if (this.$store.state.user.roles.find(x => x.key == item.to.slice(1))) {
                        return true;
                    } 
                }else{
                    return false
                }
            }
        },
    },
}
</script>
