export default [
    // {
    //     title: "الرئيسية",
    //     icon: "fi fi-rr-apps",
    //     to: "/home",
    //     role: ["ADMIN"],
    // },
    {
        title: "الحزم",
        icon: "fi fi-rr-person-dolly",
        to: "/batches",
        role: ["ADMIN"],
    },
    {
        title: "الشحنات",
        icon: "fi fi-rr-box-alt",
        to: "/order",
        role: ["ADMIN"],
    },
    // {
    //     title: "الاستمارات",
    //     icon: "fi fi-rr-form",
    //     role: ["ADMIN"],
    //     childrens: [
    //         {
    //             title: "استمارات الشيخوخة",
    //             icon: "fi fi-rr-circle-small",
    //             to: "/agingForms",
    //             role: ["ADMIN"],
    //         },
    //         {
    //             title: "استمارات الخلف",
    //             icon: "fi fi-rr-circle-small",
    //             to: "/heirForms",
    //             role: ["ADMIN"],
    //         },
    //     ]
    // },
    {
        title: "إدارة المستخدمين",
        icon: "fi fi-rr-user",
        role: ["ADMIN"],
        childrens: [
            {
                title: "الموظفين",
                icon: "fi fi-rr-circle-small",
                to: "/employee",
                role: ["ADMIN"],
            },
            {
                title: "التجار",
                icon: "fi fi-rr-circle-small",
                to: "/merchant",
                role: ["ADMIN"],
            },
            {
                title: "امناء المخازن",
                icon: "fi fi-rr-circle-small",
                to: "/warehouse_manager",
                role: ["ADMIN"],
            },
            {
                title: "مندوبين التوصيل",
                icon: "fi fi-rr-circle-small",
                to: "/delivery_delegater",
                role: ["ADMIN"],
            },
            {
                title: "مندوبين الاستلام",
                icon: "fi fi-rr-circle-small",
                to: "/receiving_delegater",
                role: ["ADMIN"],
            },
           
        ]
    },
    
    {
        title: "المخازن",
        icon: "fi fi-rr-garage",
        to: "/warehouse",
        role: ["ADMIN"],
    },
    {
        title: "المندوب",
        icon: "fi fi-rr-truck-side",
        to: "/warehouseDelegater",
        role: ["ADMIN"],
    },
    
    {
        title: "المكاتب",
        icon: "fi fi-rr-building",
        to: "/office",
        role: ["ADMIN"],
    },
    
    {
        title: "سجل الحسابات",
        icon: "fi fi-rr-coins",
        role: ["ADMIN"],
        childrens: [
            {
                title: "سجل القيود الحسابية",
                icon: "fi fi-rr-circle-small",
                to: "/transaction",
                role: ["ADMIN"],
            },
            {
                title: "اصناف القيود الحسابية",
                icon: "fi fi-rr-circle-small",
                to: "/transactionCategory",
                role: ["ADMIN"],
            },
            {
                title: "الحركات المالية للشحنات",
                icon: "fi fi-rr-circle-small",
                to: "/paymentTransaction",
                role: ["ADMIN"],
            },
        ]
    },
    // {
    //     title: "سجل الحركات",
    //     icon: "fi fi-rr-time-past",
    //     to: "/orderHistory",
    //     role: ["ADMIN"],
    // },
    {
        title: "الصلاحيات",
        icon: "fi fi-rr-lock",
        to: "/authGroup",
        role: ["ADMIN"],
    },
    
    {
        title: "المناطق",
        icon: "fi fi-rr-route",
        to: "/area",
        role: ["ADMIN"],
    },
    // {
    //     title: "وصولات التجار",
    //     icon: "fi fi-rr-notebook-alt",
    //     to: "/merchantbills",
    //     role: ["ADMIN"],
    // },
    {
        title: "الطلبات السريعة",
        icon: "fi fi-rr-time-fast",
        to: "/todo",
        role: ["ADMIN"],
    },
    {
        title: "طلبات سحب الرصيد",
        icon: "fi fi-rr-wallet",
        to: "/balanceWithdrawalRequest",
        role: ["ADMIN"],
    },
    // {
    //     title: "التقارير",
    //     icon: "fi fi-rr-file-spreadsheet",
    //     role: ["ADMIN"],
    //     childrens: [
    //         {
    //             title: "تقرير كشف الواصل للتاجر",
    //             icon: "fi fi-rr-circle-small",
    //             to: "/merchantsRecivedAmountReport",
    //             role: ["ADMIN"],
    //         },
    //     ]
    // },
];
